<template>
  <section>
    <v-card class="pa-6 bgMinsal elevation-0">
      <v-card-title class="secondary white--text mb-8">
        <span> {{ 
          id_estado === 1 ? "Actualización de insumo"
          : "Información del insumo" }} </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="7">
            <v-text-field
              label="Nombre del artículo *"
              outlined
              v-model="form.nombre"
              :error-messages="nombreError"
              @blur="$v.form.nombre.$touch"
              @input="$v.form.nombre.$touch"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Enlace del artículo *"
              outlined
              hint="Sólo se aceptan enlaces de sitios seguros"
              v-model="form.enlace"
              :error-messages="enlaceError"
              @blur="$v.form.enlace.$touch"
              @input="$v.form.enlace.$touch"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="7">
            <v-text-field
             label="Nombre proveedor"
             outlined
             v-model="nombre_proveedor"
             readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="6" md="4">
            <v-text-field
              label="Tipo de OBS"
              readonly
              :value="tipo"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="4">
            <v-text-field
              label="Segmento"
              outlined
              v-model="segmento"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="8">
            <v-text-field
              label="Producto"
              outlined
              v-model="obs"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="6" md="4">
            <v-select
              label="Unidad de medida *"
              outlined
              item-text="nombre"
              item-value="id"
              :items="unidadMedidas"
              v-model="form.id_unidad_medida"
              :error-messages="unidadMedidaError"
              @blur="$v.form.id_unidad_medida.$touch"
            ></v-select>
          </v-col>
          <v-col cols="6" sm="6" md="4">
            <v-text-field
              label="Cantidad *"
              outlined
              v-model="form.cantidad"
              :error-messages="cantidadError"
              @blur="$v.form.cantidad.$touch"
              @input="$v.form.cantidad.$touch"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <money-text-field-component
              ref="montoTotal"
              label="Monto total *"
              outlined
              v-model="form.monto"
              :error-messages="montoError"
              @blur="$v.form.monto.$touch"
              @input="$v.form.monto.$touch"
            ></money-text-field-component>
          </v-col>
        </v-row>
        <v-row>
          <v-textarea
            class="mx-3 mt-3"
            label="Especificación *"
            placeholder="Ingrese la especificación del insumo"
            outlined
            auto-grow
            clearable
            v-model="form.especificacion"
            :error-messages="especificacionError"
            @blur="$v.form.especificacion.$touch"
            @input="$v.form.especificacion.$touch"
          />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              label="Aclaraciones y observaciones"
              outlined
              auto-grow
              clearable
              v-model="form.aclaraciones"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center justify-sm-start flex-column flex-sm-row pl-sm-3">
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-0 mr-sm-3"
          color="secondary"
          outlined
          @click="$router.back()"
        >
          Volver
        </v-btn>
        <v-btn
          v-if=" id_estado === 1"
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-0 ml-sm-3"
          color="secondary"
          dark
          @click="guardar()"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";
import AutocompleteProveedores from "../Solicitud/components/AutocompleteProveedoresComponent.vue";
import { required, numeric,helpers } from "vuelidate/lib/validators";
import { mapMutations, mapState, mapActions } from "vuex";

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);
export default {
  components: { MoneyTextFieldComponent, AutocompleteProveedores },
  data: () => ({
    unidadMedidas: [],
    tipo_obs: [],
    segmentos: [],
    productos: [],
    entries: [],
    isLoading: false,
    model: null,
    nombre:null,
    id_estado:null,
    form: {
      nombre: null,
      enlace: null,
      id_proveedor_web: null,
      id_unidad_medida: null,
      cantidad: null,
      monto: null,
      especificacion: null,
      aclaraciones: null,
    },
    tipo: null,
    obs: null,
    segmento: null,
    unidad: null,

    nombre_proveedor:null,
    proveedor: {},
    id_detalle_compra_linea: null,
    id_solicitud_compra_linea: null,
    id_detalle: null,
  }),
  validations: {
    form: {
      nombre: { required },
      enlace: { required },
      id_unidad_medida: { required },
      cantidad: { required, numeric },
      monto: { required },
      especificacion: { required, txtArea },
    },
  },
  computed: {
    ...mapState(["selectedUnidad"]),
    ...mapState("compraLinea", ["compra"]),
    nombreError() {
      const errors = [];
      if (!this.$v.form.nombre.$dirty) return errors;
      !this.$v.form.nombre.required &&
        errors.push("Nombre del artículo es requerido");
      return errors;
    },
    enlaceError() {
      const errors = [];
      if (!this.$v.form.enlace.$dirty) return errors;
      !this.$v.form.enlace.required &&
        errors.push("Enlace del artículo es requerido");
      return errors;
    },
    proveedorError() {
      const errors = [];
      if (!this.$v.form.id_proveedor_web.$dirty) return errors;
      !this.$v.form.id_proveedor_web.required &&
        errors.push("Proveedor es requerido");
      return errors;
    },
    unidadMedidaError() {
      const errors = [];
      if (!this.$v.form.id_unidad_medida.$dirty) return errors;
      !this.$v.form.id_unidad_medida.required &&
        errors.push("Unidad de medida es requerido");
      return errors;
    },
    cantidadError() {
      const errors = [];
      if (!this.$v.form.cantidad.$dirty) return errors;
      !this.$v.form.cantidad.required && errors.push("Cantidad es requerido");
      !this.$v.form.cantidad.numeric &&
        errors.push("Cantidad debe de ser números");
      return errors;
    },
    montoError() {
      const errors = [];
      if (!this.$v.form.monto.$dirty) return errors;
      !this.$v.form.nombre.required && errors.push("Monto total es requerido");
      return errors;
    },
    especificacionError() {
      const errors = [];
      if (!this.$v.form.especificacion.$dirty) return errors;
      !this.$v.form.especificacion.required &&
        errors.push("Especificación es requerido");
      !this.$v.form.especificacion.txtArea &&
        errors.push("Caracteres no permitidos");
      return errors;
    },
    aclaracionesError() {
      const errors = [];
      if (!this.$v.form.aclaraciones.$dirty) return errors;
      !this.$v.form.aclaraciones.required &&
        errors.push("Caracteres no permitidos");
      return errors;
    },
  },
  methods: {
    async getUnidadMedidas() {
      const response = await this.services.Presentations.getListPresentations();
      if (response.status === 200) {
        this.unidadMedidas = response.data;
      }
    },
    async getDetalleSolicitudInsumo() {

      const { data, status } =
        await this.services.CompraLinea.getDetalleCompraLinea(
          this.id_detalle_compra_linea
        )
      if (status == 200) {
        this.form.nombre = data.nombre;
        this.form.enlace = data.enlace;
        this.form.cantidad = data.cantidad;
        this.form.monto = data.monto;
        this.$refs.montoTotal.updateValue(this.form.monto);
        this.form.aclaraciones = data.aclaraciones;
        this.form.especificacion = data.especificacion;
        this.tipo = data.tipo_obs;
        this.nombre_proveedor = data.proveedor
        this.segmento = data.segmento;
        this.obs = data.producto;
        this.form.id_unidad_medida = data?.id_unidad;
      }

    },
    
    async guardar(){
      this.$v.$touch()
      if(!this.$v.$invalid){

        let data = {}
        data.id_solicitud_compra_linea = this.id_solicitud_compra_linea
        data.nombre = this.form.nombre
        data.enlace = this.form.enlace
        data.id_unidad_medida = this.form.id_unidad_medida
        data.cantidad = this.form.cantidad
        data.monto = Number(this.form.monto)
        data.especificacion = this.form.especificacion
        data.aclaraciones = this.form.aclaraciones

        const response = await this.services.CompraLinea.putDetalleCompra(
          this.id_solicitud_compra_linea,
          this.id_detalle,
          data
        ).catch(() => {
          })
        if(response.status === 200){
          this.temporalAlert({
            show:true,
            type:"success",
            message:"El insumo ha sido actualizado exitosamente"
          })
          this.$v.$reset()
          this.$router.back()
        }
      }
    }
  },
  
  created() {
    this.id_detalle_compra_linea = Number(this.$route.params.id_detalle);
    this.id_detalle = Number(this.$route.params.id_detalle);
    this.id_solicitud_compra_linea = Number(
      this.$route.params.id_solicitud_compra_linea
    );
    this.getDetalleSolicitudInsumo();
    this.getUnidadMedidas();
    this.id_estado = this.compra?.id_estado
  },
};
</script>

<style></style>
